<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        {{ item.user_name }}
      </v-card-title>

      <v-card-text>
        <v-container fluid>

          <v-row>
            <v-col md="6">
              <v-form ref="form">
                <v-autocomplete v-model="item.sea_team" :items="seaTeams" label="Team"
                  required :rules="[v => !!v || 'Team is required']"></v-autocomplete>
                <v-autocomplete v-model="item.reports_to_user_fk" :items="activeUsers" item-value="id" item-text="name" label="Reports to"
                  required :rules="[v => !!v || 'Reports to is required']"></v-autocomplete>
                <v-autocomplete v-model="item.home_countries_fk" :items="countries" item-value="id" item-text="name" label="Home country" @change="getStates()"
                  required :rules="[v => !!v || 'Home country is required']"></v-autocomplete>
                <v-autocomplete v-model="item.home_states_fk" :items="states" item-value="id" item-text="name" label="Home state"
                ></v-autocomplete>
              </v-form>
            </v-col>
            <v-col v-if="item.users_fk" md="6">
              <AssignmentsByUser :userID="item.users_fk"></AssignmentsByUser>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pl-5 pb-5">
        <v-btn class="mr-2" :to="{ name: 'Assignments' }">Back</v-btn>
        <v-btn color="primary" :loading="saving" @click="save()">Save</v-btn>

        <v-fade-transition mode="out-in">
          <v-btn color="green darken-1" text v-show="showSaved">Saved</v-btn>
        </v-fade-transition>

      </v-card-actions>

    </v-card>

  </v-container>
</template>

<script>
import formsMixin from '@/mixins/forms'

export default {
  name: 'AssignmentDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        users_fk: null,
        user_name: null,
        sea_team: null,
        reports_to_user_fk: null,
        home_countries_fk: null,
        home_states_fk: null,
      },
      valid: false,
      loading: false,
      saving: false,
      showSaved: false,
      states: [{'id': 0, 'name': 'None'}],
      statesEndpoint: '/a/common/states'
    }
  },

  computed: {
    activeUsers () {
      return this.$store.state.system.activeUsers
    },
    countries () {
      return this.$store.state.common.countries
    },
    dataEndpoint () {
      return '/a/core/user-metadata/' + this.id
    },
    seaTeams () {
      return this.$store.state.core.seaTeams
    },
  },

  components: {
    AssignmentsByUser: () => import('@/components/AssignmentsByUser'),
  },

  mixins: [formsMixin],

  methods: {
    fetchData: function () {
      this.loading = true

      // reset data (to bring back deleted item fields)
      Object.assign(this.$data, this.$options.data.apply(this))

      var myURL = this.dataEndpoint + '?xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, [])

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
        this.getStates()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    getStates: function () {
      if (!this.item.home_countries_fk) {
        return
      }

      this.states = []

      var myURL = this.statesEndpoint + '?countries_fk=' + this.item.home_countries_fk + '&xper_page=5000&xsort=name'

      this.$http.get(myURL).then(resp => {
        this.states = resp.data.data
        this.states.push({'id': 0, 'name': 'None'})
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.saving = true

      // only send the required item fields to API
      delete this.item['id']
      delete this.item['user_name']
      delete this.item['entry_at']
      delete this.item['last_modified_at']
      delete this.item['assignments_count']

      var body = JSON.stringify(this.item)
      this.$http.put(this.dataEndpoint, body).then(resp => {
        this.showSaved = true
        this.timer = setTimeout(() => { this.showSaved = false }, 3000)
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.dataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.saving = false)
    },
  },

  created: function () {
    this.fetchData()
  },
}
</script>
